'use client';

import Script from 'next/script';

type GoogleTagManagerProps = {
  id: string;
};

export default function GoogleTagManager({ id }: GoogleTagManagerProps) {
  return (
    <>
      <noscript>
        <iframe
          height="0"
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          style={{ display: 'none', visibility: 'hidden' }}
          title="Google Tag Manager"
          width="0"
        />
      </noscript>
      <Script id="gtm-script" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
        `}
      </Script>
    </>
  );
}
